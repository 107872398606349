import { library, dom } from '@fortawesome/fontawesome-svg-core'

import {faShoppingCart,
    faDoorOpen,
    faPoundSign,
    faUmbrellaBeach,
    faPiggyBank,
    faChartLine,
    faTag,
    faHandsHelping,
    faDumbbell,
    faBook,
    faMugHot,
    faCar,
    faPray,
    faGlasses,
    faArrowDown,
    faLink,
    faShoppingBasket,
    faSearch,
    faArrowLeft,
    faCalendar,
    faTooth,
    faDownload,
    faArrowRight,
    faArrowUp,
    faFilter,
    faTimes,
    faCreditCard,
    faLock,
    faPlus,
    faMinus,
    faMobile,
    faPhone,
    faBullhorn,
    faPersonPraying,
    faBox,
    faChurch,
    faEnvelope,
    faChevronDown,
    faChevronRight,
    faFileSignature,
    faFile,
    faLocationPin} from '@fortawesome/free-solid-svg-icons';


import {faFacebookSquare,
    faTwitterSquare,
    faInstagram,
    faYoutube,
    faXTwitter
    } from '@fortawesome/free-brands-svg-icons';

library.add(faShoppingCart,
    faDoorOpen,
    faPoundSign,
    faUmbrellaBeach,
    faPiggyBank,
    faChartLine,
    faTag,
    faHandsHelping,
    faDumbbell,
    faBook,
    faMugHot,
    faCar,
    faPray,
    faGlasses,
    faArrowDown,
    faLink,
    faTooth,
    faShoppingBasket,
    faSearch,
    faDownload,
    faArrowLeft,
    faCalendar,
    faArrowRight,
    faArrowUp,
    faFilter,
    faTimes,
    faCreditCard,
    faLock,
    faPlus,
    faMinus,
    faMobile,
    faPhone,
    faBullhorn,
    faPersonPraying,
    faBox,
    faChurch,
    faEnvelope,
    faChevronDown,
    faChevronRight,
    faFileSignature,
    faFile,
    faLocationPin);

    library.add(faFacebookSquare,
        faTwitterSquare,
        faInstagram,
        faYoutube,
        faXTwitter);


function setupInjectObserver(){
    if (!window.MutationObserver){
        return false;
    }

    const newElementObserver = new MutationObserver((mutationList) => {
        for (let i = 0; i < mutationList.length; i++) {
            for (let j = 0; j < mutationList[i].addedNodes.length; j++) {
                const node = mutationList[i].addedNodes[j]
                
                if (node.tagName === "I"){
                    dom.i2svg({node: node.parentNode});
                }
                
            }
        }
    })
    
    newElementObserver.observe(document, { childList: true, subtree: true });
    
    window.addEventListener("DOMContentLoaded", ()=>{newElementObserver.disconnect()})

    return true;
}

let isObserving = false;

if (document.readyState === "loading"){
    isObserving = setupInjectObserver();
}

if (!isObserving){
    window.addEventListener("DOMContentLoaded", ()=>{ dom.i2svg();
    })
}

window.loadFontawesomeIcons = node => dom.i2svg({node});
